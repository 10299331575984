import React from "react";
import TermsCondition from "../components/FrontStatic/TermsCondition"
import {graphql} from "gatsby";
import {connect} from "react-redux";
import SEO from "../components/SEO";

const TermsConditionPage = (props) =>{
    return(
        <React.Fragment>
            <SEO {...(props?.data?.seo?.frontmatter || {})}/>
            <TermsCondition {...props} fields={props?.data?.[props.commonData.locale.locale]?.frontmatter} />
        </React.Fragment>
    )
};

const mapStateToProps = (props) => {
    return props;
};
export default connect(mapStateToProps, null)(TermsConditionPage);

export const query = graphql`
fragment TermsFragment on MarkdownRemark {
      frontmatter {
      title
      contentList {
        description
        title
      }
    }
  }
  query TermsConditionPageQuery {
    seo: markdownRemark(frontmatter: {templateKey: {eq: "terms-seo"}}) {
        frontmatter {
            title
            description
        }
  }
    en: markdownRemark(frontmatter: {templateKey: {eq: "en-terms"}}) {
    ...TermsFragment
  }
  de: markdownRemark(frontmatter: {templateKey: {eq: "de-terms"}}) {
    ...TermsFragment
  }
  es: markdownRemark(frontmatter: {templateKey: {eq: "es-terms"}}) {
    ...TermsFragment
  }
  fr: markdownRemark(frontmatter: {templateKey: {eq: "fr-terms"}}) {
    ...TermsFragment
  }
  he: markdownRemark(frontmatter: {templateKey: {eq: "he-terms"}}) {
    ...TermsFragment
  }
  it: markdownRemark(frontmatter: {templateKey: {eq: "it-terms"}}) {
    ...TermsFragment
  }
  ja: markdownRemark(frontmatter: {templateKey: {eq: "ja-terms"}}) {
    ...TermsFragment
  }
  zh: markdownRemark(frontmatter: {templateKey: {eq: "zh-terms"}}) {
    ...TermsFragment
  }
 }
`