import React, {Component} from "react";
import FrontFooter from "./common/Front-Footer";
import FrontWrapper from './common/FrontWrapper'
import IntlMessages from '../../util/IntlMessages';

class TermsCondition extends Component {
	render() {
		const { fields } = this.props;
		console.log(fields)
		return (
			<FrontWrapper location={this.props.location} isForDevice={this.props.isForDevice}>
			<div className="scroll-content-wrapper" data-scrollbar>
				<div className="page-main page-current">
					<div className="page-toload legacy-page" data-bodyclassname="legacy">
						<main className="page-content" role="main">
							<section className="splash term-con relative mb-50 mb-xs-0">
								<div className=" container sitemap-container">
									<h1 className="primary-text"> <IntlMessages id='app.signup.temsCondition' /></h1>
								</div>
							</section>

							<section className="mt-xs-20" style={{margin:'0px'}}>
								<div className="container sitemap-container">
									<div className="d-flex flex-wrap-xs">
										<div className="left-block order-xs-2">
											<div className="term-content  mb-50 mb-xs-20">
												<h2 style={{ textTransform: 'none' }}>Terms of Service</h2>
												<p>
													Welcome to the online purchase site of hk.co
												</p>
												<p>
													You can browse to find the diamonds you need,
													select and purchase them on this site. Kindly go
													through the following terms and conditions and
													also the privacy policy. It is necessary for you
													to agree to the conditions to enable us to
													permit access to the site.
												</p>
												<p>
													Once access is given on you agreeing to the
													terms and conditions (T&C) they govern your
													relationship with Hari Krishna Exports Pvt.
													Ltd., to the exclusion of any other
													agreement/document you might have signed with
													the company and current at the time of your
													accessing and using the website. Not only your
													shopping at the site, but your future visits and
													use of our company's services or the services of
													other affiliated companies are also governed by
													the same T&C unless they are found contrary to
													the guidelines and conditions already followed
													by them, in which case those conditions only
													will prevail. Insofar as the access to the
													website and purchases therefrom are concerned
													T&C will have precedence over any other
													document.
												</p>
												<p>
													Your order for purchase of goods selected by you
													after browsing the site amounts to an offer for
													purchase subject to standard site restrictions,
													merchant specific restrictions and T&C.
												</p>
												<p>
													Your order for purchase of goods selected by you
													after browsing the site amounts to an offer for
													purchase subject to standard site restrictions,
													merchant specific restrictions and T&C.
												</p>
												<p>
													The company reserves the right to
													change/alter/delete any of the clauses in T&C at
													its own discretion without any prior notice to
													you. It is therefore suggested that you go
													through the T&C from time to time to keep
													yourself abreast of any changes. It is
													understood that by agreeing to T&C, you have
													agreed to the revision in T&C that may take
													place from time to time.
												</p>
											</div>
											<div className="term-content mb-50">
												<h2 style={{ textTransform: 'none' }}>Terms and Conditions (T&C)</h2>
												<ul>
													<li>
														Hari Krishna Exports Pvt. Ltd. is the sole
														owner of the website hk.co. All the
														contents of the website including text, site
														design, logos and data provided in the
														software, graphics and audios, videos,
														icons, images etc. are wholly owned by the
														company.
													</li>
													<li>
														By registering on the website hk.co you have
														the right to browse the contents. Download
														and print them for your personal reference
														only. You have no right to
														change/modify/alter any content you have
														downloaded. Registration does not confer any
														other right on you.
													</li>
													<li>
														Any part of the content downloaded can be
														used for commercial purposes after obtaining
														prior written permission from the company
														which will specify the scope and extent up
														to which the content so downloaded can be
														commercially exploited.
													</li>
													<li>
														Unauthorized use of data downloaded for
														commercial purposes, passing the information
														contained in the website to third parties
														unauthorized, or any other use for which
														specific permission has not been obtained
														from the company will invite strict
														disciplinary and legal action.
													</li>
													<li>
														Unauthorized access to any part of this
														website or to any system /network related to
														this website is prohibited.
													</li>
													<li>
														The access given is only for lawful purposes
														.Usage of the website that violates or
														affects the rights of Hari Krishna Exports
														Pvt. Ltd. in any way inclusive of posting/
														transmitting through the website
														unlawful/threatening /abusive/defamatory
														messages, messages that infringe on the
														privacy/publicity rights,
														vulgar/obscene/objectionable messages etc.
													</li>
													<li>
														Use of access is meant for the person who
														has registered himself and for whom password
														has been given. It is not to be shared even
														temporarily with any third party.
													</li>
													<li className="mb-30">
														User is fully responsible for any
														transactions entered into using the password
														allotted to him. Hari Krishna Exports Pvt.
														Ltd. is not responsible for any consequences
														arising out of unauthorized use of the
														password allotted. User is therefore advised
														to ensure that password is properly
														protected and not allowed to get into
														unauthorized hands.
													</li>
													<p className="mb-30">
														User is fully responsible for any
														transactions entered into using the password
														allotted to him. Hari Krishna Exports Pvt.
														Ltd. is not responsible for any consequences
														arising out of unauthorized use of the
														password allotted. User is therefore advised
														to ensure that password is properly
														protected and not allowed to get into
														unauthorized hands.
													</p>
													<li>No consignment sale of stones</li>
													<li>
														A stone selected by you is subject to
														availability at the time of selection. As
														the same stones are available at our
														physical sales section, it is quite possible
														that the stones you have selected have been
														sold just minutes before your selection at
														our sales office. In such an event which is
														very rare, you have to bear with us.
													</li>
													<li>
														Local taxes if any are to be borne by the
														purchaser.
													</li>
													<li>
														Transactions are subject to jurisdiction of
														Mumbai, India
													</li>
													<li>
														KYC compliance is a must before completion
														of sale.
													</li>
													<li>
														Extra services required by the buyer will be
														charged separately.
													</li>
												</ul>
											</div>
											{fields?.contentList && fields.contentList.map(field =>
											<div className="term-content mb-50">
												<h2 style={{ textTransform: 'none' }}>{field.title}</h2>
												<div
													dangerouslySetInnerHTML={{__html: field.description}}
												/>
											</div>
											)}
										</div>

										<div className="right-block">
											<ul className="term-right-block">
												<li>Objectives</li>
												<li>Benefits to Our Customers</li>
												<li>Remarks</li>
												<li>Collection of Personal Information</li>
												<li>Use of Personal Information</li>
												<li>Security</li>
												<li>Policy Changes</li>
											</ul>
										</div>
									</div>
								</div>
							</section>

							{!this.props.isForDevice && <FrontFooter /> }
						</main>
						<div className="preload" aria-hidden="true" />
					</div>
				</div>
				<div className="page-main page-next" aria-hidden="true" />
				<div className="page-main page-prev" aria-hidden="true" />
				<footer className="footer" role="contentinfo" />
				{/*<SEO url={this.props.location.pathname.split('/')[1]} />*/}
   
			</div>
			</FrontWrapper>
		);
	}
}

export default TermsCondition;
